import React from "react";

const IconNote = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0104 6.875C11.0104 6.52981 10.7306 6.25 10.3854 6.25H3.94791C3.60273 6.25 3.32291 6.52981 3.32291 6.875C3.32291 7.22019 3.60273 7.5 3.94791 7.5H10.3854C10.7306 7.5 11.0104 7.22019 11.0104 6.875Z"
        fill="#9B9B9B"
      />
      <path
        d="M3.94792 8.75C3.60273 8.75 3.32291 9.02981 3.32291 9.375C3.32291 9.72019 3.60273 10 3.94792 10H7.85765C8.20284 10 8.48265 9.72019 8.48265 9.375C8.48265 9.02981 8.20284 8.75 7.85765 8.75H3.94792Z"
        fill="#9B9B9B"
      />
      <path
        d="M5.41841 14.75H3.32812C2.63888 14.75 2.07812 14.1892 2.07812 13.5V2.5C2.07812 1.81075 2.63888 1.25 3.32812 1.25H11.0122C11.7014 1.25 12.2622 1.81075 12.2622 2.5V6.34375C12.2622 6.68894 12.542 6.96875 12.8872 6.96875C13.2323 6.96875 13.5122 6.68894 13.5122 6.34375V2.5C13.5122 1.1215 12.3907 0 11.0122 0H3.32812C1.94963 0 0.828125 1.1215 0.828125 2.5V13.5C0.828125 14.8785 1.94963 16 3.32812 16H5.41841C5.76359 16 6.04341 15.7202 6.04341 15.375C6.04341 15.0298 5.76359 14.75 5.41841 14.75Z"
        fill="#9B9B9B"
      />
      <path
        d="M14.6175 9.04837C13.8864 8.31731 12.6969 8.31728 11.9664 9.04787L8.53501 12.4716C8.46213 12.5443 8.40838 12.6339 8.37844 12.7324L7.63116 15.1925C7.56491 15.4107 7.62263 15.6475 7.78182 15.8107C7.90107 15.9329 8.06307 15.9992 8.22923 15.9992C8.28482 15.9992 8.34094 15.9918 8.39604 15.9765L10.9187 15.2777C11.0225 15.249 11.1171 15.1939 11.1934 15.1178L14.6175 11.7001C15.3486 10.969 15.3486 9.77949 14.6175 9.04837ZM10.4266 14.117L9.15751 14.4685L9.52891 13.2458L11.8442 10.9356L12.7283 11.8197L10.4266 14.117ZM13.7341 10.8158L13.613 10.9366L12.7291 10.0527L12.8498 9.93231C13.0935 9.68862 13.49 9.68862 13.7337 9.93231C13.9774 10.176 13.9773 10.5725 13.7341 10.8158Z"
        fill="#9B9B9B"
      />
      <path
        d="M10.3854 3.75H3.94791C3.60273 3.75 3.32291 4.02981 3.32291 4.375C3.32291 4.72019 3.60273 5 3.94791 5H10.3854C10.7306 5 11.0104 4.72019 11.0104 4.375C11.0104 4.02981 10.7306 3.75 10.3854 3.75Z"
        fill="#9B9B9B"
      />
    </svg>
  );
};

export default IconNote;
