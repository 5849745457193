import React from "react";

const IconDownChevron = () => {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.69508 6.00007L8.87508 2.12527C9.09539 1.85621 9.14133 1.48402 8.99226 1.16995C8.84414 0.85589 8.5282 0.655273 8.18039 0.655273H1.82039C1.47259 0.655273 1.15665 0.855899 1.00852 1.16995C0.859461 1.48402 0.905398 1.85621 1.12571 2.12527L4.30571 6.00007C4.47633 6.2082 4.73132 6.3282 5.00039 6.3282C5.26945 6.3282 5.52445 6.2082 5.69507 6.00007H5.69508Z"
        fill="#25282B"
      />
    </svg>
  );
};

export default IconDownChevron;
