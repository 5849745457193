import React from "react";

export default function PeopleIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.7377 3.62295C1.7377 5.06964 2.91496 6.2459 4.36066 6.2459C5.80635 6.2459 6.98361 5.06864 6.98361 3.62295C6.98361 2.17726 5.80737 1 4.36066 1C2.91394 1 1.7377 2.17726 1.7377 3.62295Z"
        fill="#52575C"
      />
      <path
        d="M7.72131 11V9.89344C7.72131 8.03993 6.21416 6.53279 4.36066 6.53279C2.50715 6.53279 1 8.03993 1 9.89344V11H1.81967C6 11 5 11 6.90164 11H7.72131Z"
        fill="#52575C"
      />
      <path
        d="M7.30953 5.23629C7.15379 5.47142 6.96328 5.68151 6.74533 5.85923C7.14423 6.10441 7.61335 6.2459 8.11475 6.2459C9.56044 6.2459 10.7377 5.06864 10.7377 3.62295C10.7377 2.17726 9.56146 1 8.11475 1C7.56583 1 7.05584 1.16948 6.63428 1.4588C6.86119 1.62557 7.06187 1.82593 7.22899 2.05256C7.75 2.75 7.75 4.5 7.30953 5.23629Z"
        fill="#52575C"
      />
      <path
        d="M7.51449 7.42413C8 7.83856 8.5 8.75 8.5 11C9 11 9.73556 11 10.6557 11H11.4754V9.89344C11.4754 8.03993 9.96826 6.53279 8.11475 6.53279C7.58891 6.53279 7.09094 6.65409 6.64736 6.87019C6.96248 7.01467 7.25399 7.20177 7.51449 7.42413Z"
        fill="#52575C"
      />
    </svg>
  );
}
