import React from "react";

const IconPhone = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.14667 1.77778C3.2 2.56889 3.33333 3.34222 3.54667 4.08L2.48 5.14667C2.11556 4.08 1.88444 2.95111 1.80444 1.77778H3.14667V1.77778ZM11.9111 12.4622C12.6667 12.6756 13.44 12.8089 14.2222 12.8622V14.1867C13.0489 14.1067 11.92 13.8756 10.8444 13.52L11.9111 12.4622V12.4622ZM4 0H0.888889C0.4 0 0 0.4 0 0.888889C0 9.23556 6.76444 16 15.1111 16C15.6 16 16 15.6 16 15.1111V12.0089C16 11.52 15.6 11.12 15.1111 11.12C14.0089 11.12 12.9333 10.9422 11.9378 10.6133C11.8489 10.5778 11.7511 10.5689 11.6622 10.5689C11.4311 10.5689 11.2089 10.6578 11.0311 10.8267L9.07556 12.7822C6.56 11.4933 4.49778 9.44 3.21778 6.92444L5.17333 4.96889C5.42222 4.72 5.49333 4.37333 5.39556 4.06222C5.06667 3.06667 4.88889 2 4.88889 0.888889C4.88889 0.4 4.48889 0 4 0Z"
        fill="#A0A4A8"
      />
    </svg>
  );
};

export default IconPhone;
