import React from "react";

const IconDoor = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M13.9103 0H2.08425C1.79612 0 1.5625 0.233594 1.5625 0.52175V15.4783C1.5625 15.7664 1.79609 16 2.08425 16H13.9103C14.1985 16 14.4321 15.7664 14.4321 15.4783V0.52175C14.4321 0.233594 14.1985 0 13.9103 0ZM13.3886 14.9565H2.60597V1.04347H13.3886V14.9565H13.3886Z"
          fill="#A0A4A8"
        />
        <path
          d="M12.1716 1.73828H3.82383C3.53571 1.73828 3.30208 1.97187 3.30208 2.26003V13.7383C3.30208 14.0264 3.53568 14.26 3.82383 14.26H12.1716C12.4598 14.26 12.6934 14.0264 12.6934 13.7383V2.26003C12.6934 1.97187 12.4598 1.73828 12.1716 1.73828ZM11.6499 13.2166H4.34555V2.78178H11.6499V13.2166Z"
          fill="#A0A4A8"
        />
        <path
          d="M9.90805 6.95573C9.33268 6.95573 8.86458 7.42383 8.86458 7.99921C8.86458 8.57458 9.33268 9.04268 9.90805 9.04268C10.4834 9.04268 10.9515 8.57458 10.9515 7.99921C10.9516 7.42383 10.4834 6.95573 9.90805 6.95573Z"
          fill="#A0A4A8"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconDoor;
