const PlaceholderEmptyOrder = () => {
  return (
    <svg
      width="242"
      height="87"
      viewBox="0 0 242 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7034 49.6507C41.2485 38.2386 -7.05578 38.8804 63.7092 22.1547C134.474 5.42902 200.286 19.7596 235.741 52.7443C294.098 107.035 -77.1992 89.1611 14.7034 49.6507Z"
        fill="url(#paint0_linear_277_7974)"
      />
      <path
        d="M33.5516 4.63121C33.5516 2.18187 35.5372 0.196289 37.9865 0.196289H174.581C177.031 0.196289 179.016 2.18187 179.016 4.63121V43.6586C179.016 46.1079 177.031 48.0935 174.581 48.0935H37.9865C35.5372 48.0935 33.5516 46.1079 33.5516 43.6586V4.63121Z"
        fill="url(#paint1_linear_277_7974)"
      />
      <path
        d="M31.3342 4.63121L35.3258 1.08316L36.6561 1.97026L35.9908 3.74423L31.3342 4.63121Z"
        fill="url(#paint2_linear_277_7974)"
      />
      <path
        d="M177.242 47.2065L174.138 49.8675L173.029 48.7587L175.468 46.3195L177.242 47.2065Z"
        fill="url(#paint3_linear_277_7974)"
      />
      <rect
        x="29.5603"
        y="3.74414"
        width="145.465"
        height="47.8972"
        rx="4.43491"
        fill="url(#paint4_linear_277_7974)"
      />
      <rect
        x="29.5603"
        y="3.74414"
        width="39.0271"
        height="47.8972"
        rx="4.43491"
        fill="url(#paint5_linear_277_7974)"
      />
      <rect
        x="74.7961"
        y="9.06543"
        width="86.0369"
        height="7.09588"
        rx="1.77396"
        fill="url(#paint6_linear_277_7974)"
      />
      <rect
        x="139.102"
        y="33.0137"
        width="30.6008"
        height="12.4178"
        rx="1.77396"
        fill="url(#paint7_linear_277_7974)"
      />
      <rect
        x="74.7961"
        y="20.1533"
        width="35.9226"
        height="5.32191"
        rx="1.77396"
        fill="url(#paint8_linear_277_7974)"
      />
      <rect
        x="191.308"
        y="41.7544"
        width="17.9669"
        height="16.1442"
        fill="url(#paint9_linear_277_7974)"
        stroke="url(#paint10_linear_277_7974)"
        stroke-width="0.221745"
      />
      <path
        d="M200.923 36.9053H217.47L209.386 41.6428H191.198L200.923 36.9053Z"
        fill="url(#paint11_linear_277_7974)"
      />
      <path
        d="M209.386 41.6428L217.47 36.9053V52.5315L209.386 58.0087V41.6428Z"
        fill="url(#paint12_linear_277_7974)"
      />
      <rect
        x="190.547"
        y="35.1797"
        width="18.7289"
        height="8.0383"
        fill="url(#paint13_linear_277_7974)"
      />
      <path
        d="M200.562 32.1309H217.6L209.276 35.1799H190.547L200.562 32.1309Z"
        fill="url(#paint14_linear_277_7974)"
      />
      <path
        d="M209.276 35.1799L217.6 32.1309V38.9749L209.276 43.2182V35.1799Z"
        fill="url(#paint15_linear_277_7974)"
      />
      <path
        d="M49.0739 28.1361C49.0739 25.6868 51.0594 23.7012 53.5088 23.7012H190.104C192.553 23.7012 194.538 25.6868 194.538 28.1361V67.1634C194.538 69.6128 192.553 71.5984 190.104 71.5984H53.5088C51.0594 71.5984 49.0739 69.6128 49.0739 67.1634V28.1361Z"
        fill="url(#paint16_linear_277_7974)"
      />
      <path
        d="M46.8564 28.1361L50.848 24.588L52.1783 25.4751L51.5131 27.2491L46.8564 28.1361Z"
        fill="url(#paint17_linear_277_7974)"
      />
      <path
        d="M192.764 70.7114L189.66 73.3723L188.551 72.2636L190.99 69.8244L192.764 70.7114Z"
        fill="url(#paint18_linear_277_7974)"
      />
      <rect
        x="45.0825"
        y="27.249"
        width="145.465"
        height="47.8972"
        rx="4.43491"
        fill="url(#paint19_linear_277_7974)"
      />
      <rect
        x="45.0825"
        y="27.249"
        width="39.0271"
        height="47.8972"
        rx="4.43491"
        fill="url(#paint20_linear_277_7974)"
      />
      <rect
        x="90.3184"
        y="32.5703"
        width="86.0369"
        height="7.09588"
        rx="1.77396"
        fill="url(#paint21_linear_277_7974)"
      />
      <rect
        x="154.625"
        y="56.5195"
        width="30.6008"
        height="12.4178"
        rx="1.77396"
        fill="url(#paint22_linear_277_7974)"
      />
      <rect
        x="90.3184"
        y="43.6582"
        width="35.9226"
        height="5.32191"
        rx="1.77396"
        fill="url(#paint23_linear_277_7974)"
      />
      <mask id="path-20-inside-1_277_7974" fill="white">
        <path d="M18.074 54.7861H31.7155V67.0606H18.074V54.7861Z" />
      </mask>
      <path
        d="M18.074 54.7861H31.7155V67.0606H18.074V54.7861Z"
        fill="url(#paint24_linear_277_7974)"
      />
      <path
        d="M31.4937 54.7861V67.0606H31.9372V54.7861H31.4937Z"
        fill="url(#paint25_linear_277_7974)"
        mask="url(#path-20-inside-1_277_7974)"
      />
      <path
        d="M25.3684 51.2324H37.7783L31.7155 54.7855H18.074L25.3684 51.2324Z"
        fill="url(#paint26_linear_277_7974)"
      />
      <path
        d="M31.7156 54.7855L37.7785 51.2324V62.9521L31.7156 67.06V54.7855Z"
        fill="url(#paint27_linear_277_7974)"
      />
      <rect
        x="17.5862"
        y="49.9395"
        width="14.0467"
        height="6.02873"
        fill="url(#paint28_linear_277_7974)"
      />
      <path
        d="M25.0973 47.6523H37.8758L31.6329 49.9391H17.5862L25.0973 47.6523Z"
        fill="url(#paint29_linear_277_7974)"
      />
      <path
        d="M31.6328 49.9391L37.8758 47.6523V52.7854L31.6328 55.9678V49.9391Z"
        fill="url(#paint30_linear_277_7974)"
      />
      <rect
        x="202.233"
        y="55.7837"
        width="13.4197"
        height="12.0527"
        fill="url(#paint31_linear_277_7974)"
        stroke="url(#paint32_linear_277_7974)"
        stroke-width="0.221745"
      />
      <path
        d="M209.416 52.1191H221.826L215.764 55.6723H202.122L209.416 52.1191Z"
        fill="url(#paint33_linear_277_7974)"
      />
      <path
        d="M215.764 55.6723L221.827 52.1191V63.8388L215.764 67.9467V55.6723Z"
        fill="url(#paint34_linear_277_7974)"
      />
      <rect
        x="201.634"
        y="50.8262"
        width="14.0467"
        height="6.02873"
        fill="url(#paint35_linear_277_7974)"
      />
      <path
        d="M209.145 48.54H221.924L215.681 50.8268H201.634L209.145 48.54Z"
        fill="url(#paint36_linear_277_7974)"
      />
      <path
        d="M215.681 50.8268L221.924 48.54V53.6731L215.681 56.8555V50.8268Z"
        fill="url(#paint37_linear_277_7974)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_277_7974"
          x1="-6.23046"
          y1="49.056"
          x2="107.462"
          y2="208.241"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A7D9FF" />
          <stop offset="1" stop-color="#9AD2FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_277_7974"
          x1="187.359"
          y1="41.9842"
          x2="109.115"
          y2="-59.6134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_277_7974"
          x1="187.359"
          y1="41.9842"
          x2="109.115"
          y2="-59.6134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_277_7974"
          x1="187.359"
          y1="41.9842"
          x2="109.115"
          y2="-59.6134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_277_7974"
          x1="29.1397"
          y1="7.87869"
          x2="167.051"
          y2="90.5174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BAE7FF" />
          <stop offset="1" stop-color="#E6F7FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_277_7974"
          x1="29.4475"
          y1="7.87869"
          x2="78.4666"
          y2="15.7593"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#55B5FF" />
          <stop offset="1" stop-color="#4CB0FF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_277_7974"
          x1="165.694"
          y1="15.0351"
          x2="161.477"
          y2="-7.2909"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_277_7974"
          x1="171.432"
          y1="43.4607"
          x2="151.254"
          y2="21.7453"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_277_7974"
          x1="112.748"
          y1="24.6306"
          x2="107.472"
          y2="9.07854"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_277_7974"
          x1="191.145"
          y1="43.0563"
          x2="213.506"
          y2="47.9595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#55B5FF" />
          <stop offset="1" stop-color="#4CB0FF" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_277_7974"
          x1="210.414"
          y1="55.4121"
          x2="189.465"
          y2="45.2441"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_277_7974"
          x1="218.954"
          y1="40.8909"
          x2="213.509"
          y2="27.702"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_277_7974"
          x1="209.363"
          y1="38.7269"
          x2="219.72"
          y2="39.5097"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#55B5FF" />
          <stop offset="1" stop-color="#4CB0FF" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_277_7974"
          x1="210.334"
          y1="41.9422"
          x2="197.239"
          y2="28.6177"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_277_7974"
          x1="219.128"
          y1="34.696"
          x2="216.724"
          y2="25.381"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_277_7974"
          x1="218.07"
          y1="41.4585"
          x2="207.374"
          y2="37.9515"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_277_7974"
          x1="202.881"
          y1="65.4891"
          x2="124.638"
          y2="-36.1085"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_277_7974"
          x1="202.881"
          y1="65.4891"
          x2="124.638"
          y2="-36.1085"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_277_7974"
          x1="202.881"
          y1="65.4891"
          x2="124.638"
          y2="-36.1085"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_277_7974"
          x1="44.6619"
          y1="31.3836"
          x2="182.573"
          y2="114.022"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BAE7FF" />
          <stop offset="1" stop-color="#E6F7FF" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_277_7974"
          x1="44.9697"
          y1="31.3836"
          x2="93.9888"
          y2="39.2641"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#55B5FF" />
          <stop offset="1" stop-color="#4CB0FF" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_277_7974"
          x1="181.216"
          y1="38.54"
          x2="177"
          y2="16.214"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_277_7974"
          x1="186.954"
          y1="66.9665"
          x2="166.776"
          y2="45.2512"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_277_7974"
          x1="128.27"
          y1="48.1355"
          x2="122.995"
          y2="32.5834"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_277_7974"
          x1="18.0345"
          y1="55.8457"
          x2="34.8051"
          y2="59.5231"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#55B5FF" />
          <stop offset="1" stop-color="#4CB0FF" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_277_7974"
          x1="32.4861"
          y1="65.1125"
          x2="16.7743"
          y2="57.4865"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_277_7974"
          x1="38.8915"
          y1="54.2216"
          x2="34.8073"
          y2="44.33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_277_7974"
          x1="31.698"
          y1="52.5987"
          x2="39.4656"
          y2="53.1857"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#55B5FF" />
          <stop offset="1" stop-color="#4CB0FF" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_277_7974"
          x1="32.4264"
          y1="55.0114"
          x2="22.6054"
          y2="45.018"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_277_7974"
          x1="39.022"
          y1="49.5762"
          x2="37.2191"
          y2="42.5899"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_277_7974"
          x1="38.2285"
          y1="54.6481"
          x2="30.2065"
          y2="52.0179"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_277_7974"
          x1="202.083"
          y1="56.7324"
          x2="218.853"
          y2="60.4098"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#55B5FF" />
          <stop offset="1" stop-color="#4CB0FF" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_277_7974"
          x1="216.534"
          y1="65.9992"
          x2="200.822"
          y2="58.3733"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_277_7974"
          x1="222.94"
          y1="55.1084"
          x2="218.855"
          y2="45.2167"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_277_7974"
          x1="215.746"
          y1="53.4854"
          x2="223.514"
          y2="54.0725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#55B5FF" />
          <stop offset="1" stop-color="#4CB0FF" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_277_7974"
          x1="216.474"
          y1="55.8981"
          x2="206.654"
          y2="45.9047"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_277_7974"
          x1="223.07"
          y1="50.4639"
          x2="221.267"
          y2="43.4776"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_277_7974"
          x1="222.277"
          y1="55.5358"
          x2="214.255"
          y2="52.9056"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#40A9FF" />
          <stop offset="1" stop-color="#096DD9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PlaceholderEmptyOrder;
