const ButtonMin = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 5C0.5 2.51472 2.51472 0.5 5 0.5H19C21.4853 0.5 23.5 2.51472 23.5 5V19C23.5 21.4853 21.4853 23.5 19 23.5H5C2.51472 23.5 0.5 21.4853 0.5 19V5Z"
      fill="white"
      stroke="#2F9CF1"
    />
    <path
      d="M7.46484 12.4286C7.46484 12.6085 7.53634 12.7811 7.66359 12.9084C7.79085 13.0357 7.96345 13.1071 8.14342 13.1071H15.8577C16.0377 13.1071 16.2103 13.0357 16.3375 12.9084C16.4648 12.7811 16.5363 12.6085 16.5363 12.4286C16.5363 12.2486 16.4648 12.076 16.3375 11.9487C16.2103 11.8215 16.0377 11.75 15.8577 11.75H8.14342C7.96345 11.75 7.79085 11.8215 7.66359 11.9487C7.53634 12.076 7.46484 12.2486 7.46484 12.4286Z"
      fill="#2F9CF1"
      stroke="#2F9CF1"
      stroke-width="0.5"
    />
  </svg>
);

export default ButtonMin;
