import React from "react";

const IconProfile = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33203 0.333984C3.91603 0.333984 0.332031 3.91798 0.332031 8.33398C0.332031 12.75 3.91603 16.334 8.33203 16.334C12.748 16.334 16.332 12.75 16.332 8.33398C16.332 3.91798 12.748 0.333984 8.33203 0.333984ZM4.38803 13.358C4.73203 12.638 6.82803 11.934 8.33203 11.934C9.83603 11.934 11.94 12.638 12.276 13.358C11.188 14.222 9.82003 14.734 8.33203 14.734C6.84403 14.734 5.47603 14.222 4.38803 13.358ZM13.42 12.198C12.276 10.806 9.50003 10.334 8.33203 10.334C7.16403 10.334 4.38803 10.806 3.24403 12.198C2.42803 11.126 1.93203 9.78998 1.93203 8.33398C1.93203 4.80598 4.80403 1.93398 8.33203 1.93398C11.86 1.93398 14.732 4.80598 14.732 8.33398C14.732 9.78998 14.236 11.126 13.42 12.198V12.198ZM8.33203 3.53398C6.78003 3.53398 5.53203 4.78198 5.53203 6.33398C5.53203 7.88598 6.78003 9.13398 8.33203 9.13398C9.88403 9.13398 11.132 7.88598 11.132 6.33398C11.132 4.78198 9.88403 3.53398 8.33203 3.53398ZM8.33203 7.53398C7.66803 7.53398 7.13203 6.99798 7.13203 6.33398C7.13203 5.66998 7.66803 5.13398 8.33203 5.13398C8.99603 5.13398 9.53203 5.66998 9.53203 6.33398C9.53203 6.99798 8.99603 7.53398 8.33203 7.53398Z"
        fill="#A0A4A8"
      />
    </svg>
  );
};

export default IconProfile;
