export default function ButtonPlus(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 5C0 2.23858 2.23858 0 5 0H19C21.7614 0 24 2.23858 24 5V19C24 21.7614 21.7614 24 19 24H5C2.23858 24 0 21.7614 0 19V5Z"
        fill={props.color || "#2F9CF1"}
      />
      <path
        d="M12.7267 8.19006C12.7267 7.99746 12.6502 7.81275 12.5141 7.67656C12.3779 7.54038 12.1932 7.46387 12.0006 7.46387C11.808 7.46387 11.6233 7.54038 11.4871 7.67656C11.3509 7.81275 11.2744 7.99746 11.2744 8.19006V11.2734H8.19103C7.99844 11.2734 7.81373 11.3499 7.67754 11.4861C7.54135 11.6223 7.46484 11.807 7.46484 11.9996C7.46484 12.1922 7.54135 12.3769 7.67754 12.5131C7.81373 12.6493 7.99844 12.7258 8.19103 12.7258H11.2744V15.8091C11.2744 16.0017 11.3509 16.1864 11.4871 16.3226C11.6233 16.4588 11.808 16.5353 12.0006 16.5353C12.1932 16.5353 12.3779 16.4588 12.5141 16.3226C12.6502 16.1864 12.7267 16.0017 12.7267 15.8091V12.7258H15.8101C16.0027 12.7258 16.1874 12.6493 16.3236 12.5131C16.4598 12.3769 16.5363 12.1922 16.5363 11.9996C16.5363 11.807 16.4598 11.6223 16.3236 11.4861C16.1874 11.3499 16.0027 11.2734 15.8101 11.2734H12.7267V8.19006Z"
        fill="white"
        stroke="white"
        stroke-width="0.5"
      />
    </svg>
  );
}
