import React from "react";

const IconLocation = () => {
  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00048 0C4.42596 0 0.75 3.67452 0.75 8.25048C0.75 11.9515 6.15038 19.9731 8.21284 22.9017C8.60033 23.452 9.40063 23.452 9.78812 22.9017C11.8506 19.9731 17.251 11.9515 17.251 8.25048C17.251 3.67452 13.575 0 9.00048 0ZM9.00048 11.9995C6.90096 11.9995 5.25 10.35 5.25 8.24904C5.25 6.14952 6.89952 4.49856 9.00048 4.49856C11.1 4.49856 12.751 6.14808 12.751 8.24904C12.751 10.35 11.1 11.9995 9.00048 11.9995Z"
        fill="#FF6160"
      />
    </svg>
  );
};

export default IconLocation;
